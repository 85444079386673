import React from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';
import bg4 from '../assets/bg4.jpg';
import bg2 from '../assets/bg2.jpg';
import bg5 from '../assets/bg5.jpg';

const Home = () => {
  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 1000], [0, 0], { clamp: false });

  return (
    <div className="text-center">
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg5})`, y: y1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#031926] bg-opacity-50 flex flex-col justify-center items-center sm:items-start sm:pl-14 sm:pr-10 sm:py-20">
          <h1 className="text-[#CFFCFF] text-4xl md:text-6xl font-bold text-center sm:text-left mr-2 sm:pr-3 pb-2">
            De La Rosa Plumbing 
          </h1>
          <h3 className="text-[#CFFCFF] text-xl md:text-2xl pr-2 mb-4 text-center sm:text-left">
            Call me to get a free quote today!
          </h3>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
            className="inline-block mb-4 text-center sm:text-left"
          >
            <a href="tel:+12533658998" className="bg-[#14121F] text-[#CFFCFF] px-4 py-2 rounded font-bold flex items-center space-x-1 text-lg md:text-xl">
              <span>📞</span><span>(253) 365 8998</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      
      <div className="relative h-auto sm:py-16 bg-gray-100">
        <div className="flex flex-col sm:flex-row justify-center items-start sm:space-x-8 px-6 sm:px-20">
          <div className="relative flex flex-col w-full sm:w-1/2 space-y-4 sm:space-y-0 sm:space-x-4 order-1 sm:order-1">
            <motion.div
              className="relative w-full"
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <img src={bg4} alt="Image 1" className="w-full h-auto object-cover p-2 hidden sm:block" style={{ zIndex: 1 }} />
            </motion.div>
          </div>
          <div className="relative flex flex-col sm:w-1/2 mt-8 sm:mt-0 space-y-4 order-2 sm:order-2">
            <div className="absolute top-0 left-0 w-full h-[2px] bg-[#031926] sm:mt-2"></div>
            <div className="relative pt-6 sm:pt-4 pl-4 sm:pl-0">
              <h1 className="text-[#031926] text-4xl font-bold text-left pb-6 py-8 underline underline-offset-2">
                Why Choose De La Rosa Plumbing?
              </h1>
              <div className="text-left space-y-2 text-[#031926] text-xl pb-6">
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  • Many satisfied customers.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  • Upfront pricing.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  • Quality workmanship.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                >
                  • Locally owned and operated.
                </motion.div>
              </div>
              <motion.div className="text-[#031926] text-xl mt-4 pb-5 sm:pb-0 text-left"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }}
              >
                <span className='text-[#031926] underline underline-offset-2 font-bold'>About Us:</span> Founded by Isaiah De La Rosa in 2023, De La Rosa Plumbing has already developed a reputation for their outstading customer service & dedication. Apart from being locally owned and operated, De La Rosa Plumbing has left many customers satisfied & happy, so what are you waiting for? If you have a plumbing need, we can fix it! Get a free quote today!
              </motion.div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
