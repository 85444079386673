import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import bg4 from '../assets/bg4.jpg';
import bg5 from '../assets/bg5.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';

const Services = () => {
  const images = [bg2, bg3, bg4, bg5];
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#031926] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#CFFCFF] text-4xl font-bold mb-4">Our Services</h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.4)',
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12533658998"
              className="bg-[#14121F] text-[#CFFCFF] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Free Quote: (253) 365-8998</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <div className="max-w-4xl mx-auto text-CFFCFF px-4 pt-4 pb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4 text-white">
        </div>
      </div>

      <div className="text-center space-y-2 text-[#031926] text-2xl pb-6">
        <p className='text-3xl underline underline-offset-2 pb-4 px-4'>We currently offer the following services:</p>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          • Drain Cleaning.
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          • Leak Repair.
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          • Maintenance.
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          • Heating Services.
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
