import React from 'react';
import { FaEnvelope, FaInstagram } from 'react-icons/fa';
import { SiNextdoor } from 'react-icons/si';

const Footer = () => {
    return (
        <footer className="bg-[#CFFCFF] text-[#031926] py-4">
            <div className="container mx-auto px-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="text-center mb-4 sm:mb-0">
                        <p>&copy; 2024 De La Rosa Plumbing</p>
                        <p>Phone: (253) 365-8998</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
